<template>
  <v-container fluid>
    <v-card>
      <v-card-title class="py-6">
        <v-row>


          <v-col class="" cols="12" sm="12">
            <div style="height: 0 !important; visibility: hidden !important">
              <v-calendar
                  ref="calendar"
                  v-model="requested_date"
                  color="primary"
                  type="day"
              ></v-calendar>
              <v-spacer></v-spacer>
            </div>
            <div class="pa-0 px-0 ma-0">

              <v-btn
                  class="mr-0"
                  color="grey darken-2"
                  outlined
                  @click="setToday"
              >
                {{ $t('generic.lang_today') }}
              </v-btn>
              <v-btn
                  color="grey darken-2"
                  fab
                  icon
                  text
                  @click="$refs.calendar.prev()"
              >
                <v-icon large>
                  mdi-chevron-left
                </v-icon>
              </v-btn>

              <v-btn class="elevation-0 text-black border" color="primary" fab small @click="picker=true">
                {{ new Date(requested_date).getDate() }}
              </v-btn>

              <v-btn
                  color="grey darken-2"
                  fab
                  icon
                  small
                  @click="$refs.calendar.next()"
              >
                <v-icon large>
                  mdi-chevron-right
                </v-icon>
              </v-btn>
              <strong v-if="$refs.calendar" class="font-weight-bolder">
                {{ $refs.calendar.title }}
              </strong>

            </div>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text class="pa-0">
        <Datatable
            v-if="params.start_date > 1000 && params.end_date > 1000"
            :api-endpoint="ENDPOINTS.DATATABLES.TABLEBEE.TEMPORDERS"
            :datatable-headers="datatableHeaders"
            :excel-columns="excelColumns"
            :data="params"
            ref="orders"
            show-delete-buttons
            show-display-buttons
            @deleteEntries="deleteEntries"
            @deleteEntry="deleteEntry"
            @displayEntry="displayEntry"
            :custom-buttons="this.custombuttons"
            @approveOrder="approveOrder"
        >
          <template v-slot:item.date="{value}">
            {{ $moment(value).format('YYYY-MM-DD HH:MM') }}
          </template>

          <template v-slot:item.is_paid="{value}">
            <v-chip v-if="parseInt(value) === 1" color="primary">
              {{ $t('Paypal') }}
            </v-chip>

            <v-chip v-if="parseInt(value) === 0" color="success">
              {{ $t('generic.lang_payAtCheckout') }}
            </v-chip>
          </template>

          <template v-slot:item.total="{item}">
            {{ item.obj.orderTotal | currency }}
          </template>
        </Datatable>
      </v-card-text>
    </v-card>


    <v-dialog v-if="this.selectedOrder" max-width="700" v-model="dialog">
      <v-card>
        <v-card-title class="card-header">
          {{ this.selectedOrder.tableName }} ({{ this.selectedOrder.partyName }})
          <v-spacer/>
          <v-icon @click="closeDialog" color="error">
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text class="mx-0 px-0">
          <v-data-table :headers="this.itemsDataTableHeaders" :items="this.selectedOrder.obj.items"/>
        </v-card-text>
      </v-card>
    </v-dialog>


  </v-container>
</template>

<script>
import Datatable from "@/components/datatable/Datatable";
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";

export default {
  name: "panicTableComponent",
  components: {
    Datatable
  },
  data: () => ({
    ENDPOINTS,
    requested_date: null,
    selectedOrder: null,
    dialog: false,
    approving: false,
    custombuttons: [
      {
        text: "print",
        color: "warning",
        eventRow: "approveOrder",
        layout: {
          outlined: false,
          flat: false,
          text: false,
          icon: true
        }
      },
    ]
  }),
  watch: {
    requested_date() {
      this.$refs.orders?.getDataFromApi(this.params);
    },
  },
  computed: {
    params() {
      return {
        start_date: this.$moment.unix(new Date(this.requested_date).getTime() / 1000).startOf("days").unix(),
        end_date: this.$moment.unix(new Date(this.requested_date).getTime() / 1000).endOf("days").unix(),
      }
    },
    itemsDataTableHeaders() {
      return [
        {
          text: 'id',
          value: "id",
          hide: true
        },
        {
          text: this.$t('generic.lang_name'),
          value: "name",
        },
        {
          text: this.$t('erp.lang_quantity'),
          value: "amount",
        },
      ]
    },
    datatableHeaders() {
      return [
        {
          text: 'id',
          value: "id",
          hide: true
        },
        {
          text: this.$t('generic.lang_table'),
          value: 'tableName'
        },
        {
          text: this.$t('generic.lang_party'),
          value: 'partyName'
        },

        {
          text: this.$t('generic.lang_date'),
          value: 'date'
        },
        {
          text: this.$t('generic.lang_bookedAndPaid'),
          value: 'is_paid'
        },
        {
          value: 'obj',
          hide: true
        },
        {
          text: this.$t('generic.lang_total'),
          value: 'total',
        },
      ]
    },
    excelColumns() {
      return [
        {
          label: this.$t("erp.lang_id"),
          field: "id",
        }
      ]
    }
  },
  methods: {
    approveOrder(order) {
      this.approving = true;
      this.axios.post(ENDPOINTS.TABLEBEE.TEMPORDERS.APPROVE, {
        tableName: order?.tableName,
        partyName: [order?.partyName]
      }).then((res) => {
        if (res.status === 200) {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_success'),
            color: "success"
          });
          this.$refs.orders.getDataFromApi();
          this.$refs.orders.resetSelectedRows();
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(() => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      }).finally(() => this.approving = false);
    },
    closeDialog() {
      this.dialog = false;
      this.selectedOrder = null;
    },
    displayEntry(entity) {
      this.selectedOrder = {...entity};
      this.dialog = true;
    },
    deleteData(idsToDelete = []) {
      let self = this;
      this.$swal({
        title: this.$t('erp.lang_deleteOrder'),
        text: this.$t('erp.lang_deleteOrder'),
        icon: "warning",
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          if (!idsToDelete || !idsToDelete.length || idsToDelete.length === 0) {
            idsToDelete = [];
          }
          this.axios.post(ENDPOINTS.TABLEBEE.TEMPORDERS.DELETE, {
            UUIDs: idsToDelete,
          }).then((res) => {
            if (res.status === 200) {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_success'),
                color: "success"
              });
              self.$refs.orders.getDataFromApi();
              self.$refs.orders.resetSelectedRows();
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_errorOccurred'),
                color: "error"
              });
            }
          });
        },
        allowOutsideClick: () => !this.$swal.isLoading,
      });
    },
    deleteEntry(entry) {
      this.deleteData([entry.id]);
    },
    deleteEntries(entries) {
      this.deleteData(entries);
    },
    setToday() {
      let dt = new Date();
      this.requested_date =
          dt.getFullYear() + "-" + (dt.getMonth() + 1) + "-" + dt.getDate();
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
  },
  mounted() {
    this.setToday();
  }
}
</script>

<style scoped>

</style>
